<template>
  <div class="home">
    <v-row no-gutters :class="customizationsLoaded ? 'card-resize1' : 'card-resize2'">
      <v-col cols="5">
        <div class="text-center font-26 mt-16">Product Customization</div>
        <v-btn
          class="mt-5"
          @click="$router.push(`/customization?id=${id}`)"
          v-if="customizationsLoaded"
          >Review</v-btn
        >
      </v-col>
      <v-col cols="7" class="justify-center">
        <div v-if="customizationsLoaded" align="center" class="model-fixed">
          <CustomizationModel :customization="customization" :show="true" />
        </div>
        <div v-else class="justify-center mt-16 font-18" align="center">{{ data }}</div>
      </v-col>
    </v-row>
    <v-card> </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
//import service from "../service/apiService";
import customizationService from '../../services/customizationService';
import CustomizationModel from '@/components/customization/CustomizationModel.vue';

// @ is an alias to /src

export default {
  name: 'Home',
  components: { CustomizationModel },
  data() {
    return {
      customizationsLoaded: false,
      customization: {},
      data: '',
      frontEndUrl: process.env.VUE_APP_FRONT_END_URL,
      id: '',
      //   saturation: "",
      //   hue: "",
      //   brightness: "",
      //   opacity: "",
      //   colorSelection: "",
      //   embroiderySelection: "",
      //   productData: {},
      //   categories: [],
      //   model: ["Male", "Female"],
      //   embroideries: [],
      //   bodytypes: ["Front", "Back", "Plus Front", "Plus Back"],
      //   imageTypes: ["Core", "Shading"],
      //   colors: ["Color 1", "Color 2", "Color 3", "Color 4", "Color 5"],
      //   selectedStatus: "draft",
      //   statusArray: ["draft", "approve", "publish"],
    };
  },
  methods: {
    // findEmbForEmbColor(category) {
    //   const finalLayer = _.find(category.layers, (n) => {
    //     const obj = n.typeOfLayer === "Embroidery";
    //     return obj;
    //   });
    //   return finalLayer.embroideryMapping;
    // },
    // onColorChange() {
    //   // if (type === 'saturation') {
    //   //   this.saturation = evt.target.value;
    //   // } else if (type === 'hue') {
    //   //   this.hue = evt.target.value;
    //   // } else if (type === 'brightness') {
    //   //   this.brightness = evt.target.value;
    //   // } else {
    //   //   this.opacity = evt.target.value;
    //   // }
    //   this.$store.commit("setCustomization", this.customization);
    // },
    // uniqueColors(colorMapping) {
    //   console.log("colorMapping", colorMapping);
    //   // {
    //   //     colorMappingId: { type: Number },
    //   //     colorObject: {
    //   //         saturation: {
    //   //             type: Number,
    //   //             default: 0
    //   //         },
    //   //         hue: { type: Number, default: 0 },
    //   //         brightness: {
    //   //             type: Number,
    //   //             default: 0
    //   //         },
    //   //         opacity: {
    //   //             type: Number,
    //   //             default: 0
    //   //         }
    //   //     }
    //   // }
    //   /* eslint-disable */
    //   return _.chain(colorMapping).map("id").compact().uniq().orderBy().value();
    // },

    // async updateData() {
    //   this.dialog = false;
    //   this.productData.customizationStatus = this.selectedStatus;
    //   this.productData.categories = this.categories;
    //   const result = await service.updateProduct(
    //     this.$route.params.id,
    //     this.productData
    //   );
    //   if (result.status === 200) {
    //     this.$toasted.show("data saved!.", {
    //       theme: "bubble",
    //       position: "bottom-center",
    //       duration: 500,
    //     });
    //     this.categories = result.data.categories;
    //     this.productData = result.data;
    //   }
    // },
    async getData() {
      const result = await customizationService.getCustomizationProduct(this.$route.params.id);
      if (result.data.data == 'Customization data not found') {
        this.customizationsLoaded = false;
        this.data = 'Data Not Found !';
      } else {
        this.customization = result.data;
        this.$store.commit('setCustomization', this.customization);
        this.customizationsLoaded = true;
        this.id = result.data._id;
        console.log(this.customization, 'here22');
      }

      //   this.categories = result.data.categories;
      //   //this.hideAllToggle();
      //   this.gender = result.data.gender;
      //   this.embroideries = result.data.embroideries;
      //   this.selectedStatus = result.data.customizationStatus;
      //   this.productData = result.data;

      //   if (
      //     result.data &&
      //     result.data.product &&
      //     result.data.product.deliveryDetails.length > 0
      //   ) {
      //     _.each(result.data.product.deliveryDetails, (n) => {
      //       if (n.mrp > 0) {
      //         this.$store.commit("setProductPrice", n.mrp);
      //         console.log("here result", n.mrp);
      //         return false;
      //       }
      //     });
      //   }
    },
    // hideAllToggle() {
    //   _.each(this.categories, (category) => {
    //     _.each(category.layers, (layer) => {
    //       _.each(layer.toggles, (toggle) => {
    //         toggle.show = false;
    //       });
    //     });
    //   });
    // },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.model-wrapper {
  position: fixed;
  left: 50%;
}
.next-button-section {
  margin: 30px 0;
}
.image-upload-section {
  padding: 20px;
}
.sub-text {
  font-size: 12px;
  color: #999;
}
.category-tree {
  position: fixed;
  zoom: 0.7;
  top: 10px;
}
.embroidery-section {
  padding: 20px;
  background: #e0e1ff;
  border-radius: 20px;
}
.category-section {
  padding: 20px;
  background: #e0eeff;
  border-radius: 20px;
}
.layer-section {
  background: #e0fcfe;
  border-radius: 20px;
  padding: 20px;
}
.layer-section + .layer-section {
  margin-top: 20px;
}
.toggle-section {
  background: #fee0e0;
  border-radius: 20px;
  padding: 20px;
}

.delete-button-holder {
  position: relative;
}
.delete-button {
  position: absolute;
  zoom: 0.7;
  left: -67px;
  top: 19px;
}

.margin-left-1 {
  margin: 10px 0px 10px 80px;
}
.pull-right {
  float: right;
}
.padding-10 {
  padding: 10px;
}
.custom-range {
  margin-left: 30px;
  width: 220px;
}
.category {
  margin-top: 80px;
  .model-name {
    font-size: 15px;
  }
  .category-name {
    font-size: 32px;
  }
  .layer {
    margin-top: 20px;
    select {
      font-size: 15px;
    }
    font-size: 26px;
    margin-left: 20px;
    .toggle {
      .toggle-name {
        margin-top: 10px;
      }
      font-size: 20px;
      margin-left: 20px;
      .images-upload {
        font-size: 16px;
        margin-left: 20px;
        .image-upload-section {
          font-size: 14px;
          margin-left: 20px;
          width: 100px;
          height: 100px;
          border: 1px solid #ccc;
          display: inline-block;
          text-align: center;
          line-height: 100px;
        }
      }
    }
  }
}
.select-color {
  border: 1px solid rgb(0, 0, 0) !important;
  // padding-left: 4px;
}
.v-text-field {
  width: 80px !important;
  height: 50px !important;
}
.model-fixed {
  //position: fixed;
  left: 50%;
  //top: 10%;
  transform: translateX(-55%);
  //transform: translateY(-5%);
  // transform: scaleY(0.8);
  transform: scale(0.8);
  // padding-top: 4em;
  max-height: 400px !important;
  max-width: 750px !important;
}
.card-resize1 {
  height: 680px;
}
.card-resize2 {
  height: 200px;
}
</style>
