<template>
  <div>
    <div class="d-flex justify-center"></div>
    <div v-if="product.childProductData.length > 0">
      <div v-for="(data, index) in product.childProductData" :key="index">
        <div v-if="data">
          <div
            v-if="data && data.productCategory && tab2"
            class="font-18 butler-700 mt-6 text-center"
          >
            <div>{{ data.productCategory.toUpperCase() }}</div>
          </div>
          <div v-if="data && data.sku">{{ data.sku }}</div>
        </div>

        <ProductTable
          class="my-6 mx-6"
          :productData="data"
          :form="form"
          :colorOptions="colorOptions"
          :customizationOptions="customizationOptions"
          :edit="edit"
          :key="componentKey"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="product && product.parentProductData">
        <div
          class="font-18 butler-700 mt-6 text-center"
          v-if="product && product.parentProductData && product.parentProductData.productCategory"
        >
          <div>{{ product.parentProductData.productCategory.toUpperCase() }}</div>
        </div>
        <div v-if="product && product.parentProductData && product.parentProductData.sku">
          {{ product.parentProductData.sku }}
        </div>
      </div>
      <div v-if="product && product.parentProductData">
        <ProductTable
          class="my-6 mx-6"
          :productData="product.parentProductData"
          :form="form"
          :colorOptions="colorOptions"
          :customizationOptions="customizationOptions"
          :edit="edit"
          :save="save"
          :key="componentKey"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ProductTable from '@/components/ProductTable.vue';
import productService from '../../services/productService';

export default {
  props: ['edit', 'save', 'tab2', 'sync', 'activationDate'],
  components: {
    ProductTable,
  },
  data: () => ({
    product: {
      parentProductData: {},
      childProductData: [],
    },
    form: {
      primaryColor: [],
      primaryColorCategory: '',
      secondaryColor: [],
      secondaryColorCategory: '',
      embroideryPrimaryColor: [],
      embroideryPrimaryColorCategory: '',
      embroiderySecondaryColor: [],
      embroiderySecondaryColorCategory: '',
      revisedTimeline: '',
    },
    colorOptions: {},
    customizationOptions: [],
    title: '',
    curIndex: 0,
    componentKey: 0,
    // form2: {
    //   customize: {
    //     lengthType: [],
    //     type: [],
    //     sleeves: [],
    //     neck: [],
    //     back: [],
    //     embroidery: [],
    //     openingType: [],
    //   },
    // },
  }),
  methods: {
    async getColorCustomizationsData() {
      const result = await productService.getColorCustomizationsData();
      console.log('Color Customizations Data: ', result.data);
      this.colorOptions = result.data;
    },
    async getOneProduct(id) {
      const result = await productService.getOneProduct(id);
      this.product.parentProductData = await result.data.parentProductData;
      this.product.childProductData = await result.data.childProductData;
      let categoryAssign = [];
      if (this.product.childProductData.length > 0) {
        _.map(this.product.childProductData, (categoryConversion) => {
          if (categoryConversion.productCategory) {
            let categoryConverted = _.startCase(categoryConversion.productCategory);
            categoryConversion.productCategory = categoryConverted;
            categoryAssign.push(categoryConversion);
          }
        });
        this.product.childProductData.productCategory = categoryAssign;
      }

      console.log('Result: ', this.product);
      console.log(this.product, 'result data');
      if (result.data.childProductData.length > 0) {
        this.title = result.data.childProductData[this.curIndex].productCategory;

        //   this.form.primaryColor = filter(
        //     this.product.childProductData[this.curIndex].primaryColor,
        //     (o) => {
        //       return o.isBase == false;
        //     }
        //   );
        //   this.form.primaryColor = this.form.primaryColor.length == 0 ? [] : this.form.primaryColor;

        //   this.form.secondaryColor = filter(
        //     this.product.childProductData[this.curIndex].secondaryColor,
        //     (o) => {
        //       return o.isBase == false;
        //     }
        //   );
        //   this.form.secondaryColor =
        //     this.form.secondaryColor.length == 0 ? [] : this.form.secondaryColor;

        //   this.form.embroideryPrimaryColor = filter(
        //     this.product.childProductData[this.curIndex].embroideryPrimaryColor,
        //     (o) => {
        //       return o.isBase == false;
        //     }
        //   );
        //   this.form.embroideryPrimaryColor =
        //     this.form.embroideryPrimaryColor.length == 0 ? [] : this.form.embroideryPrimaryColor;

        //   this.form.embroiderySecondaryColor = filter(
        //     this.product.childProductData[this.curIndex].embroiderySecondaryColor,
        //     (o) => {
        //       return o.isBase == false;
        //     }
        //   );
        //   this.form.embroiderySecondaryColor =
        //     this.form.embroiderySecondaryColor.length == 0 ? [] : this.form.embroiderySecondaryColor;

        //   this.form.revisedTimeline = this.product.childProductData[this.curIndex].revisedTimeline;

        //   this.form.primaryColorCategory =
        //     this.product.childProductData[this.curIndex].primaryColorCategory;
        //   this.form.secondaryColorCategory =
        //     this.product.childProductData[this.curIndex].secondaryColorCategory;
        //   this.form.embroideryPrimaryColorCategory =
        //     this.product.childProductData[this.curIndex].embroideryPrimaryColorCategory;
        //   this.form.embroiderySecondaryColorCategory =
        //     this.product.childProductData[this.curIndex].embroiderySecondaryColorCategory;
        // } else {
        //   this.title = result.data.parentProductData.name;
        //   this.form.primaryColor = filter(this.product.parentProductData.primaryColor, (o) => {
        //     return o.isBase == false;
        //   });
        //   this.form.primaryColor = this.form.primaryColor.length == 0 ? [] : this.form.primaryColor;

        //   this.form.secondaryColor = filter(this.product.parentProductData.secondaryColor, (o) => {
        //     return o.isBase == false;
        //   });
        //   this.form.secondaryColor =
        //     this.form.secondaryColor.length == 0 ? [] : this.form.secondaryColor;

        //   this.form.embroideryPrimaryColor = filter(
        //     this.product.parentProductData.embroideryPrimaryColor,
        //     (o) => {
        //       return o.isBase == false;
        //     }
        //   );
        //   this.form.embroideryPrimaryColor =
        //     this.form.embroideryPrimaryColor.length == 0 ? [] : this.form.embroideryPrimaryColor;

        //   this.form.embroiderySecondaryColor = filter(
        //     this.product.parentProductData.embroiderySecondaryColor,
        //     (o) => {
        //       return o.isBase == false;
        //     }
        //   );
        //   this.form.embroiderySecondaryColor =
        //     this.form.embroiderySecondaryColor.length == 0 ? [] : this.form.embroiderySecondaryColor;

        //   this.form.revisedTimeline = this.product.parentProductData.revisedTimeline;

        //   this.fupdate2orm.primaryColorCategory = this.product.parentProductData.primaryColorCategory;
        //   this.form.secondaryColorCategory = this.product.parentProductData.secondaryColorCategory;
        //   this.form.embroideryPrimaryColorCategory =
        //     this.product.parentProductData.embroideryPrimaryColorCategory;
        //   this.form.embroiderySecondaryColorCategory =
        //     this.product.parentProductData.embroiderySecondaryColorCategory;
      }
    },
    async getStyleCustomizations() {
      var result = await productService.getStyleCustomizations();
      console.log('rre cust', result);
      this.customizationOptions = result.data;
      // if (this.product.parentProductData.type == 'single') {
      //   this.customizationOptions =
      //     result.data[this.product.parentProductData.productCategory.toLowerCase()];
      //   console.log('Customization options: ', this.customizationOptions);
      //   // this.customizationOptions = remove(this.customizationOptions, (o) => {
      //   //   const name =
      //   // })
      // } else {
      //   console.log(
      //     'Child prod Category: ',
      //     this.product.childProductData[this.curIndex].productCategory
      //   );
      //   this.customizationOptions =
      //     result.data[this.product.childProductData[this.curIndex].productCategory.toLowerCase()];
      // }
      return result;
    },
    async updateMultiProduct() {
      const data = {};
      data.parentProduct = this.product.parentProductData;
      data.parentProduct.activationDate = this.activationDate;
      data.childProductArray = this.product.childProductData;
      if (data.childProductArray.length > 0) {
        data.childProductArray.map((item) => {
          item.activationDate = this.activationDate;
        });
      }
      data.parentProductImages = this.product.parentProductData.images;
      // data.images = this.product.childProductArray.images
      data.parentProduct.productStatus = await this.productStatus;
      console.log(data.images, 'images');
      console.log(data, 'data here');
      try {
        const result = await productService.updateProductSet(data);
        if (result.status == 200) {
          this.$store.commit('setSnackbar', {
            content: 'Your data has been saved successfully!',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        }
      } catch (e) {
        this.$store.commit('setSnackbar', {
          content: e.response.data,
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async updateSingleProduct() {
      const data = this.product.parentProductData;
      const result = await productService.updateSingleProduct(this.id, data, (result) => {
        if (result.status == 200) {
          this.$store.commit('setSnackbar', {
            content: 'Your data has been saved successfully!',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        }
      });
    },
    async syncElasicSearch() {
      const data = {};
      data.productId = this.$route.params.id;
      const result = await productService.syncParticularProductToElasticSearchData(data);
      // const result = await productService.syncElasticSearchData();
      console.log(result);
    },
  },
  watch: {
    async save() {
      if (
        this.product &&
        this.product.childProductData &&
        this.product.childProductData.length > 0
      ) {
        if (this.save === true && this.sync === true) {
          await this.updateMultiProduct();
          // this.save = false;

          this.syncElasicSearch();
          this.getOneProduct(this.$route.params.id);
          this.$emit('update2', false);
        } else if (this.save === true && this.sync === false) {
          await this.updateMultiProduct();
          this.getOneProduct(this.$route.params.id);
          // this.save = false;
          this.$emit('update2', false);
        }
      } else {
        if (this.save === true && this.sync === true) {
          await this.updateSingleProduct();
          // this.save = false;
          await this.syncElasicSearch();
          await this.getOneProduct(this.$route.params.id);
          this.$emit('update2', false);
        } else if (this.save === true && this.sync === false) {
          await this.updateSingleProduct();
          // this.save = false;
          //this.syncElasicSearch();
          await this.getOneProduct(this.$route.params.id);
          this.$emit('update2', false);
        }
      }
      this.componentKey = this.componentKey + 1;
      this.$forceUpdate();
    },
    async tab2() {
      //  this.getOneProduct(this.id);
      //   this.$forceUpdate();

      //this.tab2 = false;
      console.log('again2');
      this.$emit('updateTab2', this.tab2);
    },
  },

  async created() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.getOneProduct(this.id);
      await this.getColorCustomizationsData();
      await this.getStyleCustomizations();
    }
    console.log('again', this.tab2, this.product.parentProductData.productCategory);
  },
};
</script>
<style lang="scss" scoped>
.mainTable {
  width: 100% !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
