<template>
  <div>
    <v-form>
      <div class="my-6 mx-6">
        <table class="mainTable">
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">PRODUCT NAME</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field
                v-model="product.parentProductData.name"
                :disabled="edit"
                v-if="product && product.parentProductData"
                >{{ product.parentProductData.name }}</v-text-field
              >
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">DESIGNER SKU CODE</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field
                v-model="product.parentProductData.designerStyleCode"
                :disabled="edit"
                v-if="product && product.parentProductData && product.parentProductData.sku"
                >{{ product.parentProductData.designerStyleCode }}</v-text-field
              >
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">COLLECTION NAME</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field v-model="product.parentProductData.collectionName" :disabled="edit">{{
                product.parentProductData.collectionName
              }}</v-text-field>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">SHORT DESCRIPTION</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field v-model="product.parentProductData.description" :disabled="edit">{{
                product.parentProductData.description
              }}</v-text-field>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">LONG DESCRIPTION</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field v-model="product.parentProductData.longDescription" :disabled="edit">{{
                product.parentProductData.longDescription
              }}</v-text-field>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">GENDER</th>
            <td class="poppins-medium font-16">
              <div class="ml-4">
                <div class="d-flex">
                  <div>
                    <input
                      type="radio"
                      v-model="product.parentProductData.category"
                      value="Male"
                      class="mr-1"
                      :disabled="edit"
                      @change="updateCategory()"
                    />
                    <label>Male</label>
                  </div>
                  <div class="ml-6">
                    <input
                      type="radio"
                      v-model="product.parentProductData.category"
                      value="Female"
                      class="mr-1"
                      :disabled="edit"
                      @change="updateCategory()"
                    />
                    <label>Female</label>
                  </div>
                  <div class="ml-6">
                    <input
                      type="radio"
                      v-model="product.parentProductData.category"
                      value="Unisex"
                      class="mr-1"
                      :disabled="edit"
                    />
                    <label>Unisex</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">STYLE</th>
            <td class="poppins-medium font-16">
              <div class="ml-4">
                <div class="d-flex">
                  <div>
                    <input
                      type="radio"
                      v-model="product.parentProductData.style"
                      value="Traditional"
                      class="mr-1"
                      :disabled="edit"
                    />
                    <label>TRADITIONAL</label>
                  </div>
                  <div class="ml-6">
                    <input
                      type="radio"
                      v-model="product.parentProductData.style"
                      value="Indo-Western"
                      class="mr-1"
                      :disabled="edit"
                    />
                    <label>INDO-WESTERN</label>
                  </div>
                  <div class="ml-6">
                    <input
                      type="radio"
                      v-model="product.parentProductData.style"
                      value="Western"
                      class="mr-1"
                      :disabled="edit"
                    />
                    <label>WESTERN</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
              TIMELINE FOR DELIVERY (WEEKS)
            </th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field
                v-model="product.parentProductData.timeline"
                :disabled="edit"
                type="number"
                min="1"
                :rules="[validateNumber]"
                pattern="[0-9]"
                >{{ product.parentProductData.timeline }}</v-text-field
              >
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">TYPE</th>
            <td class="poppins-medium font-16 pl-4">
              <div>
                <div class="d-flex">
                  <div>
                    <input
                      type="radio"
                      v-model="product.parentProductData.type"
                      value="single"
                      class="mr-1"
                      @change="updateType()"
                      :disabled="edit"
                    />
                    <label>Single</label>
                  </div>
                  <div class="ml-4">
                    <input
                      type="radio"
                      v-model="product.parentProductData.type"
                      value="compound"
                      class="mr-1"
                      @change="updateType()"
                      :disabled="edit"
                    />
                    <label>Set</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">NUMBER OF PIECES</th>
            <td class="poppins-medium font-16 pl-4">
              <!-- <v-text-field
                v-model="product.parentProductData.numberOfPieces"
                :disabled="edit"
                v-if="
                  product &&
                  product.parentProductData &&
                  product.parentProductData.numberOfPieces
                "
                >{{ product.parentProductData.numberOfPieces }}</v-text-field
              > -->
              <!-- <div class="mx-3 quantity-text-field ml-md-5 d-flex">
                <v-btn class="mr-3" @click="decrementQuantity()" :disabled="countFlag"
                  >Remove</v-btn
                >
                <v-text-field
                  @input="updateCartProduct()"
                  v-model="product.parentProductData.numberOfPieces"
                  solo
                  dense
                  outlined
                  flat
                  class="mr-3"
                  :disabled="countFlag"
                  >{{ product.parentProductData.numberOfPieces }}
                </v-text-field>
                
              </div> -->
              <div v-if="product.childProductData.length > 0">
                {{ product.childProductData.length }}
              </div>
              <div v-else>1</div>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">CUSTOMIZATION</th>
            <td class="poppins-medium font-16 pl-4">
              <div>
                <div class="d-flex">
                  <div>
                    <input
                      type="radio"
                      v-model="product.parentProductData.isCustomized"
                      value="true"
                      class="mr-1"
                      :disabled="edit"
                      @change="confirmCustomization('Yes')"
                    />
                    <label>YES</label>
                  </div>
                  <div class="ml-4">
                    <input
                      type="radio"
                      v-model="product.parentProductData.isCustomized"
                      value="false"
                      class="mr-1"
                      :disabled="edit"
                      @change="confirmCustomization('No')"
                    />
                    <label>NO</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
              CATEGORY
              <v-btn @click="incrementQuantity()">Add</v-btn>
            </th>

            <div v-if="product.childProductData.length > 0">
              <td
                class="poppins-medium font-16 pl-4 d-block"
                v-for="(item, index) in product.childProductData"
                :key="index"
              >
                <!-- <div class="d-flex">
                  <div class="poppins-medium font-16 col-2 py-2 px-0">
                    {{ item.productCategory }}
                  </div>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <p class="text-center">Customisable</p>
                    </v-col>
                    <v-btn-toggle v-model="toggle_customisable" rounded>
                      <v-btn>
                        <div class="col-3 py-2">
                          <img src="@/assets/Images/confirm.svg" alt="icon" />
                          <span class="pl-2">Yes</span>
                        </div>
                      </v-btn>confirm
                      <v-btn>
                        <div class="col-3 py-2">
                          <img
                            src="@/assets/Images/notConfirm.svg"
                            alt="icon"
                          />
                          <span class="pl-2"> No</span>
                        </div>
                      </v-btn>
                    </v-btn-toggle>
                  </v-row>

                  <div class="col-3 py-2" v-if="item.sellAsSeperate === 'true'">
                    <img src="@/assets/Images/confirm.svg" alt="icon" />
                    <span class="pl-2">Sell as Separate?</span>
                  </div>
                  <div class="col-3 py-2" v-else>
                    <img src="@/assets/Images/confirm.svg" alt="icon" />
                    <span class="pl-2">Sell as Separate?</span>
                  </div>
                </div> -->
                <v-row no-gutters v-if="item.status != 'archived'">
                  <v-col cols="4" v-if="categories && categories.length > 0" class="pr-16 pl-4">
                    <!-- <div class="poppins-medium font-16 col-2 py-2 px-0">
                      {{ item.productCategory }} -->
                    <v-select
                      :items="categories"
                      v-model="item.productCategory"
                      append-icon="▼"
                      :disabled="edit"
                      @change="show()"
                    >
                    </v-select>
                    <!-- </div> -->
                  </v-col>

                  <v-col cols="3" class="mb-2">
                    <div class="ml-4 mt-2">
                      <label>Customisable</label>

                      <div class="mb-1">
                        <input
                          type="radio"
                          v-model="item.isCustomized"
                          value="true"
                          class="mr-1"
                          :disabled="edit"
                        />
                        <label class="mr-1"> Yes </label>
                        <img src="@/assets/Images/confirm.svg" alt="icon" />
                      </div>
                      <div>
                        <input
                          type="radio"
                          v-model="item.isCustomized"
                          value="false"
                          class="mr-2"
                          :disabled="edit"
                        />

                        <label class="mr-1">No</label>
                        <img src="@/assets/Images/remove.svg" alt="icon" />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="3" class="mb-2">
                    <div class="ml-4 mt-2">
                      <label>Sell as Seperate</label>

                      <div class="mb-1">
                        <input
                          type="radio"
                          v-model="product.childProductData[index].sellAsSeperate"
                          value="true"
                          class="mr-1"
                          :disabled="edit"
                          @change="
                            confirmSellAsSeperate('Yes', product.childProductData[index]._id, index)
                          "
                        />
                        <label class="mr-1"> Yes </label>
                        <img src="@/assets/Images/confirm.svg" alt="icon" />
                      </div>
                      <div>
                        <input
                          type="radio"
                          v-model="product.childProductData[index].sellAsSeperate"
                          value="false"
                          class="mr-2"
                          :disabled="edit"
                          @change="
                            confirmSellAsSeperate('No', product.childProductData[index]._id, index)
                          "
                        />

                        <label class="mr-1">No</label>
                        <img src="@/assets/Images/remove.svg" alt="icon" />
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="2" class="mb-2">
                    <div class="mt-5">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            min-width="40px"
                            height="40px"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click="confirm(item, 'category', index)"
                          >
                            <b-icon-trash color="white"></b-icon-trash>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </td>
            </div>
            <div v-else>
              <td class="poppins-medium font-16 d-block">
                <v-row no-gutters>
                  <v-col
                    cols="4"
                    class="pr-16 pl-4"
                    v-if="
                      product &&
                      product.parentProductData &&
                      product.parentProductData.productCategory
                    "
                  >
                    <!-- <div class="poppins-medium font-16 col-2 py-2 px-0">
                      {{ product.parentProductData.productCategory }}
                    </div> -->
                    <v-select
                      :items="categories"
                      v-model="product.parentProductData.productCategory"
                      append-icon="▼"
                      :disabled="edit"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="4" class="mb-2">
                    <div class="ml-4 mt-2">
                      <label>Customisable</label>

                      <div class="mb-1">
                        <input
                          type="radio"
                          v-model="product.parentProductData.isCustomized"
                          value="true"
                          class="mr-1"
                          :disabled="edit"
                        />
                        <label class="mr-1"> Yes </label>
                        <img src="@/assets/Images/confirm.svg" alt="icon" />
                      </div>
                      <div>
                        <input
                          type="radio"
                          v-model="product.parentProductData.isCustomized"
                          value="false"
                          class="mr-2"
                          :disabled="edit"
                        />

                        <label class="mr-1">No</label>
                        <img src="@/assets/Images/remove.svg" alt="icon" />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="4" class="mb-2">
                    <div class="ml-4 mt-2">
                      <label>Sell as Seperate</label>

                      <div class="mb-1">
                        <input
                          type="radio"
                          v-model="product.parentProductData.sellAsSeperate"
                          value="true"
                          class="mr-1"
                          :disabled="edit"
                        />
                        <label class="mr-1"> Yes </label>
                        <img src="@/assets/Images/confirm.svg" alt="icon" />
                      </div>
                      <div>
                        <input
                          type="radio"
                          v-model="product.parentProductData.sellAsSeperate"
                          value="false"
                          class="mr-2"
                          :disabled="edit"
                        />

                        <label class="mr-1">No</label>
                        <img src="@/assets/Images/remove.svg" alt="icon" />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </td>
            </div>
          </tr>

          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">SIZING AND PRICING</th>
            <td
              class="poppins-medium font-16 pl-4 d-block"
              v-for="(price, index) in product.parentProductData.deliveryDetails"
              :key="index"
            >
              <v-row v-if="price.size != 'Custom'">
                <v-col
                  ><div>{{ price.size }}</div></v-col
                >
                <v-col>
                  <v-text-field v-model="price.mrp" :disabled="edit" label="Price"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="price.discount" :disabled="edit" label="Discount %">{{
                    price.discount
                  }}</v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :value="calculateDiscountPrice(index)"
                    label="Discount Price"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </td>
          </tr>

          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
              IMAGES
              <div class="mt-2 mb-2" v-if="imageUploading" align="center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <div v-else>
                <!-- <v-btn @click="incrementImageArray()" :disabled="edit" class="mb-2">Add</v-btn> -->
                <form enctype="multipart/form-data">
                  <!-- <input
                    type="file"
                    class="form-control mt-4"
                    id="files"
                    @change="incrementImageArray($event)"
                    :key="componentFrontKey"
                    accept="image/*"
                    multiple
                    :disabled="edit"
                    name="photo[]"
                  /> -->
                  <div class="d-flex">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      multiple
                      class="mt-4"
                      @change="handleImageUpload($event)"
                    />

                    <!-- <span
                    
                      >
                    </span> -->
                    <!-- <v-btn class="mt-3 mr-8"
                      >Save<img src="@/assets/Images/savechanges.svg" class="ml-1"
                    /></v-btn> -->
                  </div>
                </form>
                <v-divider></v-divider>
                OR
                <v-btn @click="boxDialog = true" :disabled="edit" class="mt-3"
                  >Fetch From Google Drive</v-btn
                >
              </div>
            </th>

            <div v-if="loaded == true">
              <td
                class="poppins-medium font-16 pl-4 d-block"
                v-for="(image, index) in product.parentProductData.images"
                :key="index"
              >
                <v-row no-gutters>
                  <template v-if="image.status != 'archived' && loaded == true">
                    <v-col cols="5">
                      <div class="d-flex">
                        <v-text-field v-model="image.imageUrl" :disabled="edit">{{
                          image.imageUrl
                        }}</v-text-field>
                        <img
                          @click="confirm(image, 'image', index)"
                          src="@/assets/Images/remove.svg"
                          alt="logo"
                          class="pl-15"
                          v-if="!image.imageUrl"
                        />
                      </div>
                    </v-col>
                    <v-col cols="4" class="px-10">
                      <div class="d-flex justify-space-around">
                        <v-card
                          height="110"
                          width="90"
                          v-if="image.imageUrl"
                          class="px-1 my-1 mx-0 mr-3"
                        >
                          <img
                            @click="confirm(image, 'image', index)"
                            src="@/assets/Images/remove.svg"
                            alt="logo"
                            class="pl-15"
                          />
                          <v-img
                            :lazy-src="
                              generateCompressImgkitURL(image.imageUrl, '686', '1030', '2:3')
                            "
                            :src="generateCompressImgkitURL(image.imageUrl, '686', '1030', '2:3')"
                            height="80"
                            width="80"
                            contain
                          ></v-img>
                        </v-card>
                        <div class="sequence-radio-btn">
                          <!-- <input
                            type="radio"
                            v-model="image.sequence"
                            value="0"
                            class="mr-1"
                            :disabled="edit"
                            @change="imageSequenceCheckboxClicked(index, image)"
                          /> -->
                          <v-text-field v-model="image.sequence"></v-text-field>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <div class="d-flex mx-4 mt-9">
                        <v-text-field
                          v-model="image.color"
                          :disabled="edit"
                          class="mr-4"
                          placeholder="hex code"
                          multiple
                          >{{ image.color }}
                        </v-text-field>

                        <div
                          class="rounded-color pa-3"
                          :style="'background-color: ' + image.color"
                        ></div>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </td>
            </div>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
              <div>PRIMARY COLOR CATEGORY</div>
            </th>

            <td class="poppins-medium font-16 pl-4 d-block">
              <v-select
                v-model="product.parentProductData.primaryColorCategory"
                item-text="name"
                :items="colorOptions.primaryColorItems"
                placeholder="Primary Color Category"
              ></v-select>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
              <div class="d-flex">
                PRIMARY COLOR

                <MultipleColorSelection
                  :model="product.parentProductData.primaryColor"
                  mask="mask"
                  :edit="edit"
                  class="px-2"
                  type="multi"
                  v-if="
                    (product.parentProductData.isCustomized && !fromCustomisation) ||
                    !fromCustomisation
                  "
                ></MultipleColorSelection>
              </div>
            </th>

            <td
              class="poppins-medium font-16 pl-4 d-block"
              v-for="(color, index) in product.parentProductData.primaryColor"
              :key="index"
            >
              <v-row no-gutters>
                <v-col
                  cols="4"
                  class="d-flex px-2 ma-1 pa-1"
                  v-if="
                    (product.parentProductData.isCustomized && !fromCustomisation) ||
                    !fromCustomisation
                  "
                >
                  <v-text-field
                    v-model="color.color"
                    :disabled="edit"
                    class="mr-4"
                    v-if="color.color"
                    placeholder="hex code"
                    >{{ color.color }}
                  </v-text-field>
                  <v-text-field
                    v-model="color.name"
                    :disabled="edit"
                    class="mr-4"
                    placeholder="color name"
                    >{{ color.name }}
                  </v-text-field>
                  <div class="rounded-color pa-3" :style="'background-color: ' + color.color"></div>

                  <img
                    src="@/assets/Images/remove.svg"
                    alt="logo"
                    class="ml-2"
                    @click="product.parentProductData.primaryColor.splice(index, 1)"
                  />
                </v-col>
                <v-col cols="4" class="d-flex px-2 ma-1 pa-1" v-else>
                  <div class="mr-4 pt-1">{{ color.color }}</div>

                  <div class="mr-4 pt-1">{{ color.name }}</div>
                  <div
                    class="rounded-color-small pa-3 pt-2"
                    :style="'background-color: ' + color.color"
                  ></div>
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
              <div>SECONDARY COLOR CATEGORY</div>
            </th>

            <td class="poppins-medium font-16 pl-4 d-block">
              <v-select
                v-model="product.parentProductData.secondaryColorCategory"
                item-text="name"
                :items="colorOptions.secondaryColorItems"
                placeholder="Secondary Color Category"
              ></v-select>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">MATERIAL & CARE</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field v-model="product.parentProductData.materialCare" :disabled="edit">{{
                product.parentProductData.materialCare
              }}</v-text-field>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">DESCRIPTION</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field v-model="product.parentProductData.description" :disabled="edit">{{
                product.parentProductData.description
              }}</v-text-field>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">DETAILS</th>
            <td class="poppins-medium font-16 pl-4">
              <v-text-field v-model="product.parentProductData.details" :disabled="edit">{{
                product.parentProductData.details
              }}</v-text-field>
            </td>
          </tr>
          <tr>
            <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">TAGS</th>
            <td class="poppins-medium font-16 pl-4">
              <v-row no-gutters>
                <v-col class="mr-3">
                  <label class="font-weight-bold">Occasions</label>
                  <v-select
                    item-text="name"
                    :items="occasions"
                    placeholder="Select Occasions"
                    append-icon="▼"
                    :disabled="edit"
                    v-model="product.parentProductData.occasionTags"
                    multiple
                  >
                  </v-select>
                </v-col>

                <v-col class="mr-3">
                  <label class="font-weight-bold">Ages</label>
                  <v-select
                    item-text="name"
                    :items="Ages"
                    placeholder=" Select Age"
                    append-icon="▼"
                    :disabled="edit"
                    v-model="product.parentProductData.ageTags"
                    multiple
                  >
                  </v-select>
                </v-col>

                <v-col>
                  <label class="font-weight-bold">Color Themes</label>
                  <v-select
                    item-text="name"
                    :items="colorThemes"
                    placeholder="Select Theme"
                    append-icon="▼"
                    :disabled="edit"
                    v-model="product.parentProductData.themeTags"
                    multiple
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col class="mr-3">
                  <label class="font-weight-bold">Preferences</label>
                  <v-select
                    item-text="name"
                    :items="preferences"
                    placeholder=" Select Preference"
                    append-icon="▼"
                    :disabled="edit"
                    v-model="product.parentProductData.preferenceTags"
                    multiple
                  >
                  </v-select>
                </v-col>
                <v-col class="mr-3">
                  <label class="font-weight-bold">Sparkles(Embroidery)</label>
                  <v-select
                    item-text="name"
                    :items="sparkles"
                    placeholder="Select Sparkle"
                    append-icon="▼"
                    :disabled="edit"
                    v-model="product.parentProductData.sparkleTags"
                    multiple
                  >
                  </v-select>
                </v-col>
                <v-col></v-col>
              </v-row>
            </td>
          </tr>
        </table>
        <ConfirmDlg ref="confirm" />
        <v-dialog v-model="boxDialog" max-width="390">
          <v-card class="" align="center">
            <v-card-title class="text-h6 mx-3"> Upload Images From Google Drive </v-card-title>
            <v-text-field
              class="box-input"
              outlined
              label="Enter FolderName"
              v-model="folderName"
            ></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="boxDialog = false"> Cancel </v-btn>
              <v-btn color="green darken-1" text @click="uploadFolderImages" :disabled="boxLoading">
                Fetch
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-form>
  </div>
</template>
<script>
import productService from '../../services/productService';
import ConfirmDlg from '../ConfirmDlg.vue';
import MultipleColorSelection from '@/components/editProducts/MulticolorSelection.vue';
import service from '../../service/apiService';
import imageUploadService from '../../services/imageUploadService.js';

import _ from 'lodash';

export default {
  components: { ConfirmDlg, MultipleColorSelection },
  props: ['edit', 'save', 'productStatus', 'sync', 'activationDate'],
  data: () => ({
    tableNames: [
      {
        header: 'PRODUCT NAME',
        data: 'Demo Text',
      },
      {
        header: 'DESIGNER SKU CODE',
        data: '12345',
      },
      {
        header: 'COLLECTION NAME',
        data: 'Demo Text',
      },
      {
        header: 'SHORT DESCRIPTION',
        data: 'Demo Text',
      },
      {
        header: 'LONG DESCRIPTION',
        data: 'Demo Text',
      },
    ],

    categories: [],
    sizes: [
      { title: 'S', price: '100,000' },
      { title: 'M', price: '100,000' },
      { title: 'L', price: '100,000' },
      { title: 'XL', price: '100,000' },
      { title: '2XL', price: '100,000' },
      { title: 'Custom', price: '100,000' },
    ],
    menus: [
      { header: 'MATERIAL & CARE', data: 'Lorem ipsum isydfgijgfwp' },
      { header: "EDITOR'S NOTES", data: 'Lorem ipsum isydfgijgfwp' },
      { header: 'PROMO CODE', data: 'Lorem ipsum isydfgijgfwp' },
      {
        header: 'TAGS',
        data: '#raw silk #sangeet #summer #mix&match $cocktail #easy',
      },
    ],
    id: '',
    tableData: {
      productName: 'Pavan',
    },
    dialog: false,
    product: {
      parentProductData: {},
      childProductData: [],
    },
    Men: [],
    Women: [],
    countFlag: true,
    occasions: [
      'Bridal',
      'Wedding',
      'Sangeet',
      'Mehendi',
      'Cocktail',
      'Resort-wear',
      'Formal-wear',
      'Casual-wear',
      'Evening-wear',
    ],
    Ages: ['<21', '21-29', '30-39', '40-49', '50-59'],
    selectedOccasions: [],
    selectedAge: [],
    colorOptions: {},
    colorThemes: [
      'Bright Shades',
      'Dark Shades',
      'Pastel Shades',
      'Neutral Shades',
      'Shades of Gold',
      'Shades of Silver',
    ],
    preferences: ['Monotone', 'Similar colours', 'Colour Blocking', 'Printed'],
    sparkles: ['Minimal', 'A subtle amount', 'The more the better', 'No preference'],
    selectedTheme: [],
    selectedPreference: [],
    selectedSparkle: [],
    boxDialog: false,
    folderId: '',
    folderName: '',
    folderImages: [],
    boxLoading: false,
    loaded: true,
    primaryColorCategory: '',
    secondaryColorCategory: '',
    componentFrontKey: 0,
    imageUploading: false,
    hideColors: false,
    fromCustomisation: false,
    moreSizes: [
      {
        size: '3XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '4XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '5XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '6XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
    ],
    // imgixConfig: '?auto=compress&w=100%',
  }),
  methods: {
    async getOneProduct(id) {
      const result = await productService.getOneProduct(id);
      this.product.parentProductData = await result.data.parentProductData;
      this.product.childProductData = await result.data.childProductData;
      let categoryAssign = [];
      if (this.product.childProductData.length > 0) {
        _.map(this.product.childProductData, (categoryConversion) => {
          if (categoryConversion.productCategory) {
            let categoryConverted = _.startCase(categoryConversion.productCategory);
            categoryConversion.productCategory = categoryConverted;
            categoryAssign.push(categoryConversion);
          }
        });
        this.product.childProductData.productCategory = categoryAssign;
      }
    },
    // imageSequenceCheckboxClicked(imageIndex, imageObject) {
    //   var tempArray = [];
    //   _.map(this.product.parentProductData.images, (item) => {
    //     if (item.imageUrl == imageObject.imageUrl) {
    //       item.sequence = 0;
    //     } else {
    //       item.sequence = 1;
    //     }
    //     tempArray.push(item);
    //   });
    //   this.product.parentProductData.images = tempArray;
    // },
    updateCategory() {
      console.log('here also');
      if (this.product.parentProductData.category == 'Female') {
        this.categories = this.Women;
      } else if (this.product.parentProductData.category == 'Male') {
        this.categories = this.Men;
      }
    },
    async updateMultiProduct() {
      var data = {};

      data.parentProduct = this.product.parentProductData;
      data.parentProduct.activationDate = this.activationDate;
      console.log(data.parentProduct, this.activationDate, 'both');
      data.childProductArray = this.product.childProductData;

      if (data.childProductArray.length > 0) {
        data.childProductArray.map((item) => {
          item.activationDate = this.activationDate;
        });
      }
      data.parentProductImages = this.product.parentProductData.images;
      var newImageArray = [];
      data.parentProduct.productStatus = await this.productStatus;

      if (this.product.parentProductData.type == 'single') {
        if (data.childProductArray.length > 0) {
          if (data.childProductArray[0].productCategory) {
            Object.assign(data.parentProduct, {
              productCategory: data.childProductArray[0].productCategory,
            });
          }
          data.childProductArray = [];
        }
      }
      const result = await productService.updateProductSet(data);
      if (result.status == 200) {
        if (result.data.data == 'Sku code already exists') {
          this.$store.commit('setSnackbar', {
            content: 'Sku code already exists',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Your data has been saved successfully!',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        }
      }
      this.getAllCategoryItems();
    },
    async updateSingleProduct() {
      const data = this.product.parentProductData;
      data.productStatus = this.productStatus;

      console.log(data, 'single Data', this.selectedOccasions);
      const result = await productService.updateSingleProduct(this.id, data, (result) => {
        if (result.status == 200) {
          if (result.data.data == 'Sku code already exists') {
            this.$store.commit('setSnackbar', {
              content: 'Sku code already exists',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else {
            this.$store.commit('setSnackbar', {
              content: 'Your data has been saved successfully!',
              icon: 'mdi-check-circle',
              color: 'success ',
              isVisible: true,
            });
          }
        }
      });
      this.getAllCategoryItems();
    },
    async getAllCategoryItems() {
      this.categories = [];
      const result = await productService.getAllCategoryItems();

      this.Women = await result.data.female.map((item) => {
        return item.name;
      });

      this.Men = await result.data.male.map((item) => {
        return item.name;
      });
      await this.updateCategory();
    },
    async updateType() {
      //  console.log(this.product.parentProductData.type, 'type');
      if (this.product.parentProductData.type == 'compound') {
        this.countFlag = false;
        // this.product.childProductData.splice(0, 1);
        //  await this.incrementQuantity();
      } else {
        this.countFlag = true;
      }
    },
    async incrementQuantity() {
      //this.product.parentProductData.numberOfPieces += 1;
      //  this.product.childProductData.length += 1

      const data = {};
      data.isChild = true;
      data.customize = {
        type: [],
        back: [],
        embroidery: [],
        lengthType: [],
        neck: [],
        openingType: [],
        sleeves: [],
      };
      data.sizes = [];
      data.color = [];
      data.images = [];
      data.deliveryDetails = [];
      data.secondaryColorCategory = '';
      data.primaryColor = [];
      data.secondaryColor = [];
      data.embroideryPrimaryColor = [];
      data.embroiderySecondaryColor = [];
      data.category = this.product.parentProductData.category;
      data.productCategory = '';
      data.vendor = this.product.parentProductData.vendor;
      data.status = 'enabled';
      console.log(this.product.childProductData, 'here only');
      await this.product.childProductData.push(data);
      this.product.parentProductData.numberOfPieces = await this.product.childProductData.length;
      console.log(this.product.parentProductData.numberOfPieces, 'number');
      console.log(this.product.childProductData, 'array');
    },
    // async incrementImageArray() {
    //   // if (this.product.parentProductData.images.length <= 19) {
    //   //   const data = {};
    //   //   data.sequence = this.product.parentProductData.images.length + 1;
    //   //   data.imageUrl = '';
    //   //   data.product = this.product.parentProductData._id;
    //   //   await this.product.parentProductData.images.push(data);
    //   // } else {
    //   //   this.$store.commit('setSnackbar', {
    //   //     content: 'Maximum upto 20 images can be added',
    //   //     icon: 'mdi-alert-circle',
    //   //     color: 'error ',
    //   //     isVisible: true,
    //   //   });
    //   // }
    //   //this.loader = true;
    //   this.imageUploading = true;
    //   if (this.product.parentProductData.images.length <= 19) {
    //     this.componentFrontKey += 1;

    //     //this.product.parentProductData.images.push(data);
    //   } else {
    //     this.$store.commit('setSnackbar', {
    //       content: 'Maximum upto 20 images can be added',
    //       icon: 'mdi-alert-circle',
    //       color: 'error ',
    //       isVisible: true,
    //     });
    //   }
    // },
    async deleteProductById(item, index) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        console.log('item._id is', item._id);
        if (item._id) {
          console.log('cond 1');
          // this.childProductData[index] = {};
          item.status = 'archived';
        } else {
          this.product.childProductData.splice(index, 1);
        }
      }
      this.loaded = false;
      this.loaded = true;
    },

    async confirm(item, name, index) {
      if (name == 'category') {
        if (this.product.childProductData.length <= 2) {
          if (this.product.parentProductData.type == 'compound') {
            await this.$store.commit('setSnackbar', {
              content: 'Minimum two categories needed for product type set',
              icon: 'mdi-alert-circle',
              color: 'error ',
              isVisible: true,
            });
          } else {
            if (this.product.childProductData.length == 1) {
              await this.$store.commit('setSnackbar', {
                content: 'Minimum one category needed for product type single',
                icon: 'mdi-alert-circle',
                color: 'error ',
                isVisible: true,
              });
            } else {
              this.deleteProductById(item, index);
            }
          }
        } else {
          this.deleteProductById(item, index);
        }
      } else if (name == 'image') {
        if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
          // item.status = 'archived';
          // item.imageUrl = '';
          console.log('image delete option');
          if (item._id) {
            console.log('cond 1');
            // this.childProductData[index] = {};
            item.status = 'archived';
          } else {
            console.log('cond 2', index);
            this.product.parentProductData.images.splice(index, 1);
            this.$forceUpdate();
            this.loaded = false;
            this.loaded = true;
          }
        }
      }
    },
    async IncrementColorArray() {
      const data = {};
      (data.category = ''), (data.isBase = true);
      data.color = '';
      await this.product.parentProductData.primaryColor.push(data);
    },

    async getColorCustomizationsData() {
      const result = await productService.getColorCustomizationsData();
      console.log('Color Customizations Data: ', result.data);
      this.colorOptions = result.data;
    },

    async uploadFolderImages() {
      this.imageUploading = true;
      this.boxDialog = false;
      this.boxLoading = true;
      const data = {};
      data.name = this.folderName;
      const result = await productService.uploadImagesGoogleDrive(data);
      this.folderImages = await result.data;
      console.log(result, 'box upload');
      await this.boxUpload();
      this.boxLoading = false;
    },

    async boxUpload() {
      if (this.product.parentProductData.images.length <= 19) {
        this.folderImages.map((item) => {
          const data = {};
          data.sequence = this.product.parentProductData.images.length + 1;
          data.imageUrl = item.downloadUrl;
          data.product = this.product.parentProductData._id;
          this.product.parentProductData.images.push(data);
        });
        this.imageUploading = false;
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Maximum upto 20 images can be added',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async syncElasicSearch() {
      const data = {};
      data.productId = this.$route.params.id;
      const result = await productService.syncParticularProductToElasticSearchData(data);
      console.log(result);
    },
    async handleImageUpload(event) {
      this.imageUploading = true;
      if (event.target.files.length + this.product.parentProductData.images.length <= 20) {
        this.componentFrontKey += 1;
        console.log(event.target.files, 'event-files');
        Array.from(event.target.files).forEach((file) => {
          imageUploadService.uploadImage(file, (result) => {
            if (result.status === 200) {
              // this.$toasted.show('Image added!.', {
              //   theme: 'bubble',
              //   position: 'bottom-center',
              //   duration: 1000,
              // });
              const data = {};
              data.sequence = this.product.parentProductData.images.length + 1;
              data.product = this.product.parentProductData._id;
              data.imageUrl = result.data.meta.location;
              this.product.parentProductData.images.push(data);
            }
          });
        });
        this.imageUploading = false;
      } else {
        this.imageUploading = false;
        this.$store.commit('setSnackbar', {
          content: 'Maximum upto 20 images can be added',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async confirmSellAsSeperate(value, productId, index) {
      if (value == 'No') {
        if (
          await this.$refs.confirm.open(
            'Confirm',
            'Are you sure you want to make sell as seperate false?'
          )
        ) {
          await this.deleteProductElasticSync(productId);
        } else {
          this.product.childProductData[index].sellAsSeperate = true;
          this.$forceUpdate();
          console.log('working-here');
        }
      }
    },

    async deleteProductElasticSync(id) {
      const data = [];
      data.push(id);
      console.log(data, 'here');
      const result = await productService.deleteProductElasticSync(data);
      console.log(result);
      // this.$router.go();
    },

    calculateDiscountPrice(index) {
      const item = this.product.parentProductData.deliveryDetails[index];
      let discountPrice;
      if (item && item.mrp && item.discount) {
        discountPrice = item.mrp - (item.mrp * item.discount) / 100;
        this.product.parentProductData.deliveryDetails[index].discountPrice =
          Math.round(discountPrice);
        return Math.round(discountPrice);
      } else {
        return '';
      }
    },

    async confirmCustomization(value) {
      if (value == 'Yes') {
        if (
          await this.$refs.confirm.open(
            'Confirm',
            'Are you sure you want to make this as customisable Product ?'
          )
        ) {
          this.product.parentProductData.isCustomized = 'true';
          this.product.parentProductData.primaryColor.length = 0;
          this.hideColors = true;
        } else {
          this.product.parentProductData.isCustomized = 'false';
        }
      }
    },
    validateNumber(value) {
      if (value === undefined || value === null || value < 1 || value.toString().includes('-')) {
        return 'Number Should not be less than 1';
      }
      return true;
    },
  },
  watch: {
    async save() {
      if (this.save == true && this.sync == false) {
        await this.updateMultiProduct();
        this.getOneProduct(this.$route.params.id);
      } else if (this.save === true && this.sync == true) {
        await this.updateMultiProduct();
        await this.syncElasicSearch();
        this.getOneProduct(this.$route.params.id);
      }
      this.$emit('update1', false);
    },
  },

  async created() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.getOneProduct(this.id);
      this.product.parentProductData.primaryColor.map((item) => {
        if (item && item.fromCustomization && item.fromCustomization === true) {
          this.fromCustomisation = true;
        }
      });

      const xxlIndex = this.product.parentProductData.deliveryDetails.findIndex(
        (size) => size.size === 'XXL'
      );

      if (xxlIndex !== -1) {
        const has3XL = this.product.parentProductData.deliveryDetails.some(
          (size) => size.size === '3XL'
        );
        if (!has3XL) {
          this.product.parentProductData.deliveryDetails.splice(xxlIndex + 1, 0, ...this.moreSizes);
        }
      }

      await this.getAllCategoryItems();
      await this.updateType();
      await this.getColorCustomizationsData();
    }
  },
};
</script>
<style lang="scss" scoped>
.mainTable {
  width: 100% !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.tabs-section {
  border: 1px solid #dbdbdb !important;
  border-radius: 5px !important;
  background-color: red !important;
  font-size: 16px !important;
}
.quantity-text-field.v-input__slot {
  width: 120px !important;
}
.rounded-color {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
.rounded-color-small {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
.box-input {
  width: 260px !important;
  text-align: center;
}
.sequence-radio-btn {
  margin: auto !important;
}
// .quantity-text-field {
//   zoom: 0.8 !important;
//   width: 120px;
// }
</style>
