<template>
  <div>
    <div class="d-flex">
      <table class="mainTable">
        <tr v-for="pricolor in pricolors" :key="pricolor.text">
          <th class="poppins-medium font-16 py-4 pl-4 col-5">
            {{ pricolor.text }}
          </th>
          <td>
            <div class="d-flex">
              <div v-if="pricolor.text == 'PRIMARY COLOR'">
                <v-select
                  item-text="name"
                  :items="colorOptions.primaryColorItems ? colorOptions.primaryColorItems : []"
                  v-model="productData.primaryColorCategory"
                  mask="mask"
                  class="pt-0"
                  :disabled="edit"
                  append-icon="▼"
                ></v-select>
                <div
                  v-for="primaryHashCode in productData.primaryColor"
                  :key="primaryHashCode.index"
                >
                  <!--  -->
                  <div
                    class="d-flex"
                    v-if="(productData.isCustomized && !fromCustomisation) || !fromCustomisation"
                  >
                    <v-text-field
                      v-model="primaryHashCode.color"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="hex code"
                      >{{ primaryHashCode.color }}</v-text-field
                    >
                    <v-text-field
                      v-model="primaryHashCode.name"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="color name"
                      >{{ primaryHashCode.name }}</v-text-field
                    >
                    <div
                      class="rounded-color mr-1"
                      :style="'background-color: ' + primaryHashCode.color"
                    ></div>
                  </div>
                  <div class="d-flex" v-else>
                    <div class="mr-4 mt-1">{{ primaryHashCode.color }}</div>

                    <div class="mr-4 mt-1">{{ primaryHashCode.name }}</div>
                    <div
                      class="rounded-color mr-1 mb-1"
                      :style="'background-color: ' + primaryHashCode.color"
                    ></div>
                  </div>
                </div>
              </div>

              <div v-if="pricolor.text == 'EMBROIDERY PRIMARY COLOR'">
                <v-select
                  item-text="name"
                  :items="
                    colorOptions.embroideryPrimaryItems ? colorOptions.embroideryPrimaryItems : []
                  "
                  v-model="productData.embroideryPrimaryColorCategory"
                  mask="mask"
                  :disabled="edit"
                  class="pt-0"
                  append-icon="▼"
                ></v-select>
                <div
                  v-for="embPrimaryHashCode in productData.embroideryPrimaryColor"
                  :key="embPrimaryHashCode.index"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="embPrimaryHashCode.color"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="hex code"
                      >{{ embPrimaryHashCode.color }}</v-text-field
                    >
                    <v-text-field
                      v-model="embPrimaryHashCode.name"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="color name"
                      >{{ embPrimaryHashCode.name }}</v-text-field
                    >
                    <div
                      class="rounded-color mr-1"
                      :style="'background-color: ' + embPrimaryHashCode.color"
                    ></div>
                  </div>
                </div>
              </div>

              <div align="right" class="mr-4">
                <v-col class="ma-0 pa-0 pt-3" cols="1" xs="1" sm="1" md="1" lg="1">
                  <MultipleColorSelection
                    v-if="
                      (pricolor.text == 'PRIMARY COLOR' &&
                        productData &&
                        productData.isCustomized &&
                        !fromCustomisation) ||
                      !fromCustomisation
                    "
                    :model="productData.primaryColor"
                    mask="mask"
                    :edit="edit"
                    type="multi"
                  ></MultipleColorSelection>
                  <MultipleColorSelection
                    v-if="productData && pricolor.text == 'EMBROIDERY PRIMARY COLOR'"
                    :model="productData.embroideryPrimaryColor"
                    mask="mask"
                    :edit="edit"
                    type="multi"
                  ></MultipleColorSelection>
                </v-col>
              </div>
              <div :class="['upload-circle-shade']" class="pl-4"></div>
            </div>
          </td>
        </tr>
        <tr v-for="pridetail in pridetails" :key="pridetail.text">
          <th class="poppins-medium font-16 py-2 pl-4 col-4">{{ pridetail.heading }}</th>

          <td v-if="loaded">
            <div v-if="pridetail.heading == 'TYPE' || pridetail.heading == 'OPENING TYPE'">
              <div
                v-if="
                  customizationOptions[
                    productData.productCategory && productData.productCategory.toLowerCase()
                  ]
                "
              >
                <v-select
                  label=""
                  :items="
                    customizationOptions[productData.productCategory.toLowerCase()][pridetail.value]
                      ? customizationOptions[productData.productCategory.toLowerCase()][
                          pridetail.value
                        ]
                      : []
                  "
                  item-text="name"
                  v-model="productData.customize[pridetail.value][0]"
                  return-object
                  :disabled="edit"
                  :item-disabled="checkIsItemDisabled"
                  append-icon="▼"
                ></v-select>
              </div>
            </div>
            <div v-else>
              <div
                v-if="
                  customizationOptions[
                    productData.productCategory && productData.productCategory.toLowerCase()
                  ]
                "
              >
                <v-select
                  multiple
                  label=""
                  :items="
                    customizationOptions[productData.productCategory.toLowerCase()][pridetail.value]
                      ? customizationOptions[productData.productCategory.toLowerCase()][
                          pridetail.value
                        ]
                      : []
                  "
                  item-text="name"
                  v-model="productData.customize[pridetail.value]"
                  return-object
                  :disabled="edit"
                  :item-disabled="checkIsItemDisabled"
                  append-icon="▼"
                ></v-select>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <table class="mainTable">
        <tr v-for="seccolor in seccolors" :key="seccolor.text">
          <th class="poppins-medium font-16 py-4 pl-4 col-6">
            {{ seccolor.text }}
          </th>

          <td>
            <div class="d-flex">
              <div v-if="seccolor.text == 'SECONDARY COLOR'">
                <v-select
                  item-text="name"
                  :items="colorOptions.secondaryColorItems ? colorOptions.secondaryColorItems : []"
                  v-model="productData.secondaryColorCategory"
                  mask="mask"
                  class="pt-0"
                  :disabled="edit"
                  append-icon="▼"
                ></v-select>
                <div
                  v-for="secondaryHashCode in productData.secondaryColor"
                  :key="secondaryHashCode.index"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="secondaryHashCode.color"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="hex code"
                      >{{ secondaryHashCode.color }}</v-text-field
                    >
                    <v-text-field
                      v-model="secondaryHashCode.name"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="color name"
                      >{{ secondaryHashCode.name }}</v-text-field
                    >
                    <div
                      class="rounded-color mr-1"
                      :style="'background-color: ' + secondaryHashCode.color"
                    ></div>
                  </div>
                </div>
              </div>

              <div v-if="seccolor.text == 'EMBROIDERY SECONDARY COLOR'">
                <v-select
                  item-text="name"
                  :items="
                    colorOptions.embroiderySecondaryItems
                      ? colorOptions.embroiderySecondaryItems
                      : []
                  "
                  v-model="productData.embroiderySecondaryColorCategory"
                  mask="mask"
                  :disabled="edit"
                  class="pt-0"
                  append-icon="▼"
                ></v-select>
                <div
                  v-for="embSecondaryHashCode in productData.embroiderySecondaryColor"
                  :key="embSecondaryHashCode.index"
                >
                  <div class="d-flex">
                    <v-text-field
                      v-model="embSecondaryHashCode.color"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="hex code"
                      >{{ embSecondaryHashCode.color }}</v-text-field
                    >
                    <v-text-field
                      v-model="embSecondaryHashCode.name"
                      :disabled="edit"
                      class="mr-4"
                      placeholder="color name"
                      >{{ embSecondaryHashCode.name }}</v-text-field
                    >
                    <div
                      class="rounded-color mr-1"
                      :style="'background-color: ' + embSecondaryHashCode.color"
                    ></div>
                  </div>
                </div>
              </div>

              <div align="right" class="mr-4">
                <v-col class="ma-0 pa-0 pt-3" cols="1" xs="1" sm="1" md="1" lg="1">
                  <MultipleColorSelection
                    v-if="productData && seccolor.text == 'SECONDARY COLOR'"
                    :model="productData.secondaryColor"
                    mask="mask"
                    :edit="edit"
                    type="multi"
                  ></MultipleColorSelection>

                  <MultipleColorSelection
                    v-if="productData && seccolor.text == 'EMBROIDERY SECONDARY COLOR'"
                    :model="productData.embroiderySecondaryColor"
                    mask="mask"
                    type="multi"
                  ></MultipleColorSelection>
                </v-col>
              </div>
              <div :class="['upload-circle-shade']" class="pl-4"></div>
            </div>
          </td>
        </tr>
        <tr v-for="secdetail in secdetails" :key="secdetail.text">
          <th class="poppins-medium font-16 py-2 pl-4 col-6">
            {{ secdetail.heading }}
          </th>
          <td v-if="secdetail.heading === 'LINING MATERIAL'">
            <v-text-field
              v-model="productData.liningMaterial"
              :disabled="edit"
              class="mr-4"
              placeholder="Lining Material"
              >{{ productData.liningMaterial }}</v-text-field
            >
          </td>
          <td v-if="secdetail.heading === 'PRIMARY FABRIC'">
            <v-select
              item-text="name"
              :items="colorOptions.fabricItems ? colorOptions.fabricItems : []"
              v-model="productData.primaryFabric"
              class="mx-4"
              :disabled="edit"
              append-icon="▼"
            >
            </v-select>
          </td>
          <td v-if="secdetail.heading === 'CUSTOMISABLE?'">
            <!-- <div class="text-center">
              {{ productData.isCustomized ? 'YES' : 'NO' }}
            </div> -->
            <div class="ml-4 mt-2">
              <label>Customisable</label>

              <div class="mb-1">
                <input
                  type="radio"
                  v-model="productData.isCustomized"
                  value="true"
                  class="mr-1"
                  :disabled="edit"
                  @change="confirmCustomization('Yes')"
                />
                <label class="mr-1"> Yes </label>
                <img src="@/assets/Images/confirm.svg" alt="icon" />
              </div>
              <div>
                <input
                  type="radio"
                  v-model="productData.isCustomized"
                  value="false"
                  class="mr-2"
                  :disabled="edit"
                  @change="confirmCustomization('No')"
                />

                <label class="mr-1">No</label>
                <img src="@/assets/Images/remove.svg" alt="icon" />
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <table class="mainTable">
      <tr>
        <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">DESCRIPTION</th>
        <td class="poppins-medium font-16 pl-4">
          <v-text-field v-model="productData.longDescription" :disabled="edit">{{
            productData.longDescription
          }}</v-text-field>
        </td>
      </tr>
      <tr>
        <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">SIZING AND PRICING</th>
        <td
          class="poppins-medium font-16 pl-4 d-block"
          v-for="(price, index) in productData.deliveryDetails"
          :key="price.index"
        >
          <v-row>
            <v-col
              ><div>{{ price.size }}</div></v-col
            >
            <v-col>
              <v-text-field v-model="price.mrp" :disabled="edit" label="Price">{{
                price.mrp
              }}</v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="price.discount" :disabled="edit" label="Discount %">{{
                price.discount
              }}</v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :value="calculateDiscountPrice(index)"
                label="Discount Price"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </td>
      </tr>
      <tr>
        <th class="maroon-txt poppins-medium font-16 py-2 pl-4 col-3">
          IMAGES
          <!-- <v-btn @click="incrementImageArray()" :disabled="edit">Add</v-btn> -->
          <div class="mt-2 mb-2" v-if="imageUploading" align="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else>
            <!-- <v-btn @click="incrementImageArray()" :disabled="edit" class="mb-2">Add</v-btn> -->
            <form enctype="multipart/form-data">
              <div class="d-flex">
                <input
                  type="file"
                  id="file"
                  name="file"
                  multiple
                  class="mt-4"
                  @change="handleImageUpload($event)"
                />
              </div>
            </form>
            <v-divider></v-divider>
            OR
            <v-btn @click="boxDialog = true" :disabled="edit" class="mt-3"
              >Fetch From Google Drive</v-btn
            >
          </div>
        </th>
        <template v-if="imgLoaded == true">
          <td
            class="poppins-medium font-16 pl-4 d-block"
            v-for="(image, index) in productData.images"
            :key="index"
          >
            <v-row no-gutters>
              <template v-if="image.status != 'archived' && imgLoaded == true">
                <v-col cols="5">
                  <div class="d-flex">
                    <v-text-field v-model="image.imageUrl" :disabled="edit">{{
                      image.imageUrl
                    }}</v-text-field>
                    <img
                      @click="confirm(image, index)"
                      src="@/assets/Images/remove.svg"
                      alt="logo"
                      class="pl-15"
                      v-if="!image.imageUrl"
                    />
                  </div>
                </v-col>
              </template>
              <v-col cols="4" class="px-10">
                <div class="d-flex justify-space-around">
                  <v-card
                    height="110"
                    width="90"
                    v-if="image.imageUrl && image.status != 'archived'"
                    class="px-1 my-1 mx-0 mr-3"
                  >
                    <img
                      @click="confirm(image, index)"
                      src="@/assets/Images/remove.svg"
                      alt="logo"
                      class="pl-15"
                    />
                    <v-img
                      :lazy-src="generateCompressImgkitURL(image.imageUrl, '686', '1030', '2:3')"
                      :src="generateCompressImgkitURL(image.imageUrl, '686', '1030', '2:3')"
                      height="80"
                      width="80"
                    ></v-img>
                  </v-card>
                  <div class="sequence-radio-btn">
                    <!-- <input
                      type="radio"
                      v-model="image.sequence"
                      value="0"
                      class="mr-1"
                      :disabled="edit"
                      @change="imageSequenceCheckboxClicked(index, image)"
                    /> -->
                    <v-text-field v-model="image.sequence"></v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex mx-4 mt-9">
                  <v-text-field
                    v-model="image.color"
                    :disabled="edit"
                    class="mr-4"
                    placeholder="hex code"
                    multiple
                    >{{ image.color }}
                  </v-text-field>

                  <div class="rounded-color pa-3" :style="'background-color: ' + image.color"></div>
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
      </tr>
    </table>

    <!-- <v-dialog v-model="primaryColorPickerFlag" width="300px">
      <v-card class="text-center">
        <v-color-picker
          hide-mode-switch
          @input="setHexValue()"
          dot-size="25"
          mode="hex"
          swatches-max-height="200"
        ></v-color-picker>

        <div>
          <v-btn @click="primaryColorPickerFlag = false" class="inactive-btn my-2 mb-2"
            >Select Shade</v-btn
          >
        </div>
      </v-card>
    </v-dialog> -->
    <ConfirmDlg ref="confirm" />
    <v-dialog v-model="boxDialog" max-width="390">
      <v-card class="" align="center">
        <v-card-title class="text-h6 mx-3"> Upload Images From Google Drive </v-card-title>
        <v-text-field
          class="box-input"
          outlined
          label="Enter FolderName"
          v-model="folderName"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="boxDialog = false"> Cancel </v-btn>
          <v-btn color="green darken-1" text @click="uploadFolderImages" :disabled="boxLoading">
            Fetch
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import MultipleColorSelection from '@/components/editProducts/MulticolorSelection.vue';
import ConfirmDlg from '../components/ConfirmDlg.vue';
//import service from '../service/apiService';
import productService from '../services/productService';
import imageUploadService from '../../src/services/imageUploadService.js';

export default {
  props: ['productData', 'form', 'colorOptions', 'customizationOptions', 'edit', 'save'],
  components: { MultipleColorSelection, ConfirmDlg },
  data: () => ({
    primaryColorPickerFlag: false,
    items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
    lengths: [{ text: 'Knee Length', price: '+ 8000' }],
    pricolors: [
      { text: 'PRIMARY COLOR', value: 'primaryColorCategory' },
      {
        text: 'EMBROIDERY PRIMARY COLOR',
        value: 'embroideryPrimaryColorCategory',
      },
    ],
    pridetails: [
      { heading: 'TYPE', value: 'type' },
      { heading: 'SLEEVES', value: 'sleeves' },
      { heading: 'OPENING TYPE', value: 'openingType' },
      { heading: 'EMBROIDERY COVERAGE', value: 'embroidery' },
      { heading: 'LENGTHTYPE', value: 'lengthType' },
      { heading: 'NECK', value: 'neck' },
      { heading: 'BACK', value: 'back' },
    ],
    seccolors: [{ text: 'SECONDARY COLOR' }, { text: 'EMBROIDERY SECONDARY COLOR' }],
    secdetails: [
      { heading: 'LINING MATERIAL' },
      { heading: 'PRIMARY FABRIC' },
      { heading: 'CUSTOMISABLE?' },
    ],
    custArray: [],
    productDataCopy: {},
    loaded: false,
    imgLoaded: true,
    defaultDeliveryDetails: [
      {
        size: '2XS',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'XS',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'S',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'M',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'L',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'XL',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'XXL',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '3XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '4XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '5XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: '6XL',
        mrp: '0',
        discount: 0,
        discountPrice: 0,
      },
      {
        size: 'Free Size',
        mrp: 0,
        discount: 0,
        discountPrice: 0,
      },
    ],
    imageUploading: false,
    boxDialog: false,
    folderId: '',
    folderName: '',
    boxLoading: false,
    fromCustomisation: false,
  }),

  methods: {
    checkIsItemDisabled(item) {
      return item.isBase == true;
    },
    // async incrementImageArray() {
    //   if (this.productData && this.productData.images && this.productData.images.length <= 4) {
    //     const data = {};
    //     data.sequence = this.productData.images.length + 1;
    //     data.imageUrl = '';
    //     data.product = this.productData._id;
    //     await this.productData.images.push(data);
    //   } else {
    //     this.$store.commit('setSnackbar', {
    //       content: 'Maximum upto 5 images can be added',
    //       icon: 'mdi-alert-circle',
    //       color: 'error ',
    //       isVisible: true,
    //     });
    //   }
    // },
    async handleImageUpload(event) {
      this.imageUploading = true;
      if (event.target.files.length + this.productData.images.length <= 10) {
        this.componentFrontKey += 1;

        Array.from(event.target.files).forEach((file) => {
          imageUploadService.uploadImage(file, (result) => {
            if (result.status === 200) {
              // this.$toasted.show('Image added!.', {
              //   theme: 'bubble',
              //   position: 'bottom-center',
              //   duration: 1000,
              // });
              const data = {};
              data.sequence = this.productData.images.length + 1;

              data.product = this.productData._id;

              data.imageUrl = result.data.meta.location;

              this.productData.images.push(data);
            }
          });
        });
        this.imageUploading = false;
      } else {
        this.imageUploading = false;
        this.$store.commit('setSnackbar', {
          content: 'Maximum upto 10 images can be added',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    hasWhiteSpace(s) {
      return /\s/g.test(s);
    },
    async confirm(item, index) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        if (item._id) {
          item.status = 'archived';
        } else {
          this.productData.images.splice(index, 1);
          this.$forceUpdate();
          this.loaded = false;
          this.loaded = true;
        }
      }
    },
    async uploadFolderImages() {
      this.imageUploading = true;
      this.boxDialog = false;
      this.boxLoading = true;
      const data = {};
      data.name = this.folderName;
      const result = await productService.uploadImagesGoogleDrive(data);
      this.folderImages = await result.data;

      await this.boxUpload();
      this.boxLoading = false;
    },
    calculateDiscountPrice(index) {
      const item = this.productData.deliveryDetails[index];
      const discountPrice = item.mrp - (item.mrp * item.discount) / 100;
      return discountPrice.toFixed(2);
    },

    async boxUpload() {
      if (this.productData.images.length <= 10) {
        this.folderImages.map((item) => {
          const data = {};
          data.sequence = this.productData.images.length + 1;
          data.imageUrl = item.downloadUrl;
          data.product = this.productData._id;
          this.productData.images.push(data);
        });
        this.imageUploading = false;
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Maximum upto 10 images can be added',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },

    imageSequenceCheckboxClicked(imageIndex, imageObject) {
      var tempArray = [];
      _.map(this.productData.images, (item) => {
        if (item.imageUrl == imageObject.imageUrl) {
          item.sequence = 0;
        } else {
          item.sequence = 1;
        }
        tempArray.push(item);
      });
      this.productData.images = tempArray;
    },
    async confirmCustomization(value) {
      if (value == 'Yes') {
        if (
          await this.$refs.confirm.open(
            'Confirm',
            'Are you sure you want to make this as customisable Product ?'
          )
        ) {
          this.productData.isCustomized = 'true';
          this.productData.primaryColor.length = 0;
        } else {
          this.productData.isCustomized = 'false';
        }
      }
    },
  },
  created() {
    var newArray = [];
    if (this.productData && this.productData.sellAsSeperate) {
      _.each(this.defaultDeliveryDetails, (deliveryDetailsObject) => {
        const finalLayer = _.find(this.productData.deliveryDetails, (n) => {
          const obj = n.size == deliveryDetailsObject.size;
          return obj;
        });
        if (finalLayer) {
          if (finalLayer.mrp > 0) {
            newArray.push(finalLayer);
          } else {
            newArray.push(deliveryDetailsObject);
          }
        } else {
          newArray.push(deliveryDetailsObject);
        }
      });
    }
    this.productData.deliveryDetails = newArray;

    this.productData &&
      this.productData.primaryColor &&
      this.productData.primaryColor.map((item) => {
        if (item && item.fromCustomization && item.fromCustomization === true) {
          this.fromCustomisation = true;
        }
      });
    if (this.productData && this.productData.primaryColor) {
      const result = this.productData.primaryColor.filter((element) => element.isBase == false);
      this.productData.primaryColor = result;
      this.productData.primaryColor =
        this.productData.primaryColor.length == 0 ? [] : this.productData.primaryColor;
    }

    const value = this.hasWhiteSpace(this.productData.productCategory);

    if (value == true) {
      let category = '';
      category = this.productData.productCategory;
      this.productData.productCategory = category.replace(/\s+/g, '');
    } else {
      let category1 = '';
      category1 = this.productData.productCategory;
      if (category1) {
        this.productData.productCategory = category1;
      }
    }
    if (!this.productData.customize) {
      this.productData.customize = {
        type: [],
        lengthType: [],
        neck: [],
        back: [],
        sleeves: [],
        openingType: [],
        embroidery: [],
      };
    }
    this.loaded = false;
    this.loaded = true;

    //   // }
  },
  watch: {
    async productData() {
      const value = this.hasWhiteSpace(this.productData.productCategory);

      if (value == true) {
        let category = '';
        category = this.productData.productCategory;
        this.productData.productCategory = category.replace(/\s+/g, '');
      } else {
        let category1 = '';
        category1 = this.productData.productCategory;
        if (category1) {
          this.productData.productCategory = category1;
        }
      }

      // if (!this.productData.customize) {
      //   this.productData.customize = {
      //     type: [],
      //     lengthType: [],
      //     neck: [],
      //     back: [],
      //     sleeves: [],
      //     openingType: [],
      //     embroidery: [],
      //   };
      // }
      // this.loaded = false;

      //this.loaded = true;
    },
  },
};
</script>

<style scoped>
.mainTable {
  width: 100% !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.rounded-color {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.box-input {
  width: 260px !important;
  text-align: center;
}
.sequence-radio-btn {
  margin: auto !important;
}
</style>
