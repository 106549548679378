<template>
  <div>
    <div class="font-22 butler-700 mt-10 text-center">
      {{ this.product.parentProductData.sku }} - {{ this.product.parentProductData.name }}
    </div>
    <div class="mt-7 d-flex justify-center">
      <v-btn-toggle>
        <div v-for="option in options" :key="option.text" class="ml-7">
          <v-btn text @click="actionbtn(option.title)">
            <img :src="option.image" alt="logo" class="pr-1" />
            <span class="poppins-medium font-12"> {{ option.title }}</span>
          </v-btn>
        </div>
      </v-btn-toggle>
    </div>
    <div class="mt-7 d-flex justify-center">
      <v-btn-toggle v-model="productStatus">
        <v-btn value="draft"> Draft </v-btn>
        <v-btn value="reviewed"> Reviewed </v-btn>
        <v-btn value="active"> Active </v-btn>

        <v-btn value="deactivated"> Deactivated </v-btn>
      </v-btn-toggle>
    </div>
    <div class="justify-center mt-7" align="center">
      <v-card class="tab-section" width="50%">
        <v-tabs fixed-tabs v-model="tab" min-width="40px">
          <v-tab v-for="item in items" :key="item.tab" :href="'#' + item.name">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-card>
      <v-tabs-items v-model="tab" class="mt-3">
        <v-tab-item v-for="item in items" :key="item.tab" :value="item.name">
          <div v-if="item.tab === 'Step1A'">
            <Step1
              :edit="disabled"
              :save="saveFlag1"
              v-on:update1="updateSave1"
              :productStatus="productStatus"
              :sync="sync"
              :activationDate="activationDate"
            />
          </div>
          <!--As per vendor flow step2 & step3 interchanged -->
          <div v-if="item.tab === 'Step1B'">
            <Step2
              :edit="disabled"
              :save="saveFlag2"
              v-on:update2="updateSave2"
              :productStatus="productStatus"
              :tab2="update2"
              v-on:updateTab2="updateTab2"
              :key="componentKey"
              :sync="sync"
              :activationDate="activationDate"
            />
          </div>
          <!--As per vendor flow step2 & step3 interchanged -->
          <div v-if="item.tab === 'Step2'">
            <Step3
              :save="saveFlag3"
              :edit="disabled"
              v-on:update3="updateSave3"
              :productStatus="productStatus"
              :sync="sync"
              :activationDate="activationDate"
              :key="componentKey"
            />
            <!-- <Step3
              :save="saveFlag3"
              :edit="disabled"
              v-on:update3="updateSave3"
              :productStatus="productStatus"
              :tab3="update3"
              v-on:updateTab3="updateTab3"
            /> -->
          </div>
          <div v-if="item.tab === 'Step3'">
            <Step4 />
          </div>
        </v-tab-item>
      </v-tabs-items>
      <template>
        <div class="text-center">
          <v-dialog v-model="dialogQuery" width="500">
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"> Write Your Query </v-card-title>

              <v-textarea
                v-model="query"
                filled
                name="input-7-4"
                label="Fill your Query"
              ></v-textarea>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="createProductQuery()"> Send </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <ConfirmDlg ref="confirm" />
    </div>
  </div>
</template>
<script>
import raisequery from '@/assets/Images/raisequery.svg';
import active from '@/assets/Images/active.svg';
import Edit from '@/assets/Images/Edit.svg';
import remove from '@/assets/Images/remove.svg';
import savechanges from '@/assets/Images/savechanges.svg';
//import ProductTable from "@/components/ProductTable.vue";
import productService from '../../services/productService';
import Step1 from '@/components/editProducts/Step1.vue';
import Step2 from '@/components/editProducts/Step2.vue';
//import Step3 from '@/components/editProducts/Step3.vue';
import Step4 from '@/components/editProducts/Step4.vue';
import Step3 from '@/components/editProducts/Step_3.vue';
import ConfirmDlg from '../../components/ConfirmDlg.vue';
//let isFirstTime = true;
export default {
  components: {
    // ProductTable,
    Step1,
    Step2,
    // Step3,
    Step4,
    Step3,
    ConfirmDlg,
  },
  data: () => ({
    options: [
      {
        title: 'Raise Query',
        image: raisequery,
      },
      // {
      //   title: "Active",
      //   image: active,
      // },
      {
        title: 'Edit',
        image: Edit,
      },
      {
        title: 'Delete',
        image: remove,
      },
      {
        title: 'Save Changes',
        image: savechanges,
      },
    ],
    tableNames: [
      {
        header: 'PRODUCT NAME',
        data: 'Demo Text',
      },
      {
        header: 'DESIGNER SKU CODE',
        data: '12345',
      },
      {
        header: 'COLLECTION NAME',
        data: 'Demo Text',
      },
      {
        header: 'SHORT DESCRIPTION',
        data: 'Demo Text',
      },
      {
        header: 'LONG DESCRIPTION',
        data: 'Demo Text',
      },
    ],
    genders: ['Men', 'Women', 'UNISEX'],
    styles: ['Traditional', 'Indo-western', 'Western'],
    categorys: [{ title: 'Dupatta' }, { title: 'Lehenga' }, { title: 'Blouse' }],
    sizes: [
      { title: 'S', price: '100,000' },
      { title: 'M', price: '100,000' },
      { title: 'L', price: '100,000' },
      { title: 'XL', price: '100,000' },
      { title: '2XL', price: '100,000' },
      { title: 'Custom', price: '100,000' },
    ],
    menus: [
      { header: 'MATERIAL & CARE', data: 'Lorem ipsum isydfgijgfwp' },
      { header: "EDITOR'S NOTES", data: 'Lorem ipsum isydfgijgfwp' },
      { header: 'PROMO CODE', data: 'Lorem ipsum isydfgijgfwp' },
      {
        header: 'TAGS',
        data: '#raw silk #sangeet #summer #mix&match $cocktail #easy',
      },
    ],
    id: '',
    tableData: {
      productName: 'Pavan',
    },
    disabled: true,
    product: {
      parentProductData: {},
      childProductData: [],
    },
    dialogQuery: false,
    query: '',
    tab: 'Step1A',
    items: [
      { tab: 'Step1A', name: 'Step1A' },
      { tab: 'Step1B', name: 'Step1B' },
      { tab: 'Step2', name: 'Step2' },
      { tab: 'Step3', name: 'Step3' },
    ],
    saveFlag1: false,
    saveFlag2: false,
    saveFlag3: false, // this.update2 = 'true';
    // this.update3 = 'true';
    status: ['draft', 'reviewed', 'active', 'deactivated'],
    productStatus: '',
    update2: false,
    update3: false,
    componentKey: 0,
    vendorId: '',
    sync: false,
    activationDate: '',
  }),

  async created() {
    this.id = this.$route.params.id;
    if (this.id) {
      await this.getOneProduct(this.id);
      this.productStatus = await this.product.parentProductData.productStatus;

      const storedTab = localStorage.getItem('activeTab');
      const storedId = JSON.parse(localStorage.getItem('product'));

      if (storedTab && storedId === this.id) {
        this.tab = JSON.parse(storedTab);
      }
    }
  },
  methods: {
    async actionbtn(title) {
      if (title == 'Edit') {
        this.disabled = !this.disabled;
      } else if (title == 'Save Changes') {
        if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to Save Changes ?')) {
          if (this.productStatus == 'active') {
            this.sync = true;
          } else {
            this.sync = false;
            this.deleteProductElasticSync();
          }
          if (this.tab == 'Step1A') {
            this.saveFlag1 = true;

            console.log(this.saveFlag1, 'saveFlag1');
          } else if (this.tab == 'Step1B') {
            this.saveFlag2 = true;
            console.log(this.saveFlag2, 'saveFlag2');
          } else {
            this.saveFlag3 = true;
          }
        }
      } else if (title == 'Raise Query') {
        this.dialogQuery = true;
      } else if (title == 'Delete') {
        if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
          this.deleteProductById(this.id);
        }
      }
    },

    async getOneProduct(id) {
      const result = await productService.getOneProduct(id);
      this.product.parentProductData = await result.data.parentProductData;
      this.product.childProductData = await result.data.childProductData;
      this.vendorId = await result.data.parentProductData.vendor;
      console.log(this.product, 'result data');
    },
    // async updateSingleProduct() {
    //   const data = this.product.parentProductData;
    //   const result = await productService.updateSingleProduct(
    //     this.id,
    //     data,
    //     (result) => {
    //       if (result.status == 200) {
    //         this.$store.commit("setSnackbar", {
    //           content: "Your data has been saved successfully!",
    //           icon: "mdi-check-circle",
    //           color: "success ",
    //           isVisible: true,
    //         });
    //       }
    //     }
    //   );
    // },
    async updateProductStatus(option) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          `Are you sure you want to Change status to ${option} ?`
        )
      ) {
        const data = {};
        data.productStatus = option((data.productId = this.id));
        const result = await productService.updateProductStatus(data, (result) => {
          if (result.status == 200) {
            this.$store.commit('setSnackbar', {
              content: 'Your data has been saved successfully!',
              icon: 'mdi-check-circle',
              color: 'success ',
              isVisible: true,
            });
          }
        });
      } else {
        this.productStatus = await this.product.parentProductData.productStatus;
      }
    },
    async createProductQuery() {
      this.dialogQuery = false;
      const data = {};
      data.query = await this.query;
      data.productId = await this.id;
      data.vendorId = await this.vendorId;
      data.raisedBy = 'Admin';
      console.log(data, 'data');
      const result = await productService.createProductQuery(data, (result) => {
        if (result.status == 200) {
          this.$store.commit('setSnackbar', {
            content: 'Your Query sent successfully!',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        }
      });
    },
    async deleteProductById(id) {
      const result = await productService.deleteProduct(id);

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Deleted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    updateSave1(event) {
      this.saveFlag1 = event.target;
    },
    updateSave2(event) {
      this.saveFlag2 = event.target;
    },
    updateSave3(event) {
      this.saveFlag3 = event.target;
    },
    updateTab2(event) {
      this.update2 = event.target;
    },
    updateTab3(event) {
      this.update3 = event.target;
    },
    async deleteProductElasticSync() {
      const data = [];
      data.push(this.id);
      if (this.product.childProductData.length > 0) {
        this.product.childProductData.map((child) => {
          data.push(child._id);
        });
      }

      console.log(data, 'here');
      const result = await productService.deleteProductElasticSync(data);
      console.log(result);
      // this.$router.go();
    },
    // async syncElasicSearch() {
    //   // const data = {}
    //   const result = await productService.syncElasticSearchData();
    //   console.log(result);
    // },
  },
  watch: {
    tab() {
      console.log(this.tab);
      localStorage.setItem('activeTab', JSON.stringify(this.tab));
      localStorage.setItem('product', JSON.stringify(this.id));

      if (this.tab == 'Step1B') {
        this.componentKey = this.componentKey + 1;
        this.update2 = true;
      } else if (this.tab == 'Step2') {
        this.componentKey = this.componentKey + 1;
        this.update3 = true;
      }
    },

    productStatus(newValue, oldValue) {
      // if (isFirstTime) {
      //   isFirstTime = false;
      //   return;
      // }
      if (newValue === 'active' && oldValue != 'active' && oldValue != '') {
        const today = new Date().toISOString();
        this.activationDate = today;
        this.componentKey = this.componentKey + 1;
        console.log(oldValue, 'to', newValue, this.activationDate);
      } else {
        this.componentKey = this.componentKey + 1;
      }
      console.log(oldValue, 'to', newValue);
    },
  },
};
</script>
<style lang="scss" scoped>
.mainTable {
  width: 100% !important;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.tabs-section {
  border: 1px solid #dbdbdb !important;
  border-radius: 5px !important;
  background-color: red !important;
  font-size: 16px !important;
}
</style>
